@import "src/styles/variables";
@import "src/styles/breakpoints";

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.Modal {
  &_root {
    position: fixed;
    z-index: 69;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-duration: .5s;
    animation-name: fade;
    animation-direction: alternate;
  }

  &_header {
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 12px 0;
    @include breakpoint(desktop) {
      width: 600px;
    }
    @include breakpoint(tablet) {
      width: calc(100% - 256px);
    }
  }

  &_logo {
    width: 100px;
  }

  &_text {
    font-family: 'Jura', sans-serif;
    color: #FFFFFF;

    @include breakpoint(desktop) {
      font-weight: normal;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
    }
    @include breakpoint(mobile) {
      font-weight: normal;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
    }
  }

  &_poster {
    width: calc(100% - 32px);
    @include breakpoint(desktop) {
      width: 600px;
    }
    @include breakpoint(tablet) {
      width: calc(100% - 256px);
    }
  }
}