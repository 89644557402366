@import "src/styles/variables";
@import "src/styles/breakpoints";

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

.Menu {
  &_root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: fixed;
    z-index: 6;
    animation-duration: .5s;
    animation-name: fade;
    animation-direction: alternate;

    @include breakpoint(desktop) {
      padding: 200px 16px 16px 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
    }
    @include breakpoint(tablet) {
      padding: 200px 16px 16px 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
    }
    @include breakpoint(mobile) {
      padding: 200px 16px 16px 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
    }
  }

  &_logo {
    @include breakpoint(desktop) {
      position: absolute;
      top: 24px;
      left: 24px;
    }
    @include breakpoint(tablet) {
      position: absolute;
      top: 24px;
      left: 24px;
    }
    @include breakpoint(mobile) {
      position: absolute;
      top: 24px;
      left: 24px;
    }
  }

  &_close {
    @include breakpoint(desktop) {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    @include breakpoint(tablet) {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    @include breakpoint(mobile) {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }

  &_items {
    @include breakpoint(desktop) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @include breakpoint(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > a {
      font-family: 'Jura', sans-serif;
      color: #FFFFFF;
      text-decoration: none;

      @include breakpoint(desktop) {
        font-weight: normal;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        margin: 0 0 19px 0;
      }
      @include breakpoint(tablet) {
        font-weight: normal;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        margin: 0 0 19px 0;
      }
      @include breakpoint(mobile) {
        font-weight: normal;
        font-size: 24px;
        font-style: normal;
        line-height: normal;
        margin: 0 0 19px 0;
      }
    }
  }

  &_footerText {
    display: flex;

    @include breakpoint(desktop) {
      position: absolute;
      bottom: 24px;
      right: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 32px;
      justify-content: space-between;
    }
    @include breakpoint(tablet) {
      position: absolute;
      bottom: 24px;
      right: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 32px;
      justify-content: space-between;
    }
    @include breakpoint(mobile) {
      position: absolute;
      bottom: 24px;
      right: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 32px;
      justify-content: space-between;
    }

    &Item {
      display: flex;
      justify-content: flex-end;

      & > p {
        font-family: 'Jura', sans-serif;
        color: #FFFFFF;

        @include breakpoint(desktop) {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 8px 0 0;
        }
        @include breakpoint(tablet) {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 8px 0 0;
        }
        @include breakpoint(mobile) {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 8px 0 0;
        }
      }

      & > a {
        font-family: 'Jura', sans-serif;
        color: #FFFFFF;

        @include breakpoint(desktop) {
          font-weight: normal;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
        @include breakpoint(tablet) {
          font-weight: normal;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
        @include breakpoint(mobile) {
          font-weight: normal;
          font-size: 14px;
          font-style: normal;
          line-height: normal;
        }
      }
    }
  }
}