@mixin breakpoint($device) {
  @if $device == mobile {
    @media only screen and (max-width: 767px) {
      @content
    }
  } @else if $device == tablet {
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      @content
    }
  } @else if $device == desktop {
    @media only screen and (min-width: 1201px) {
      @content
    }
  }
}