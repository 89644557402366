@import "src/styles/variables";
@import "src/styles/breakpoints";

.AboutPage {
  &_root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    @include breakpoint(desktop) {
      padding: 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      row-gap: 0;
      column-gap: 16px;
    }
    @include breakpoint(tablet) {
      padding: 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 8px;
    }
    @include breakpoint(mobile) {
      padding: 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 8px;
    }
  }

  &_visual {
    @include breakpoint(desktop) {
      width: 100%;
      flex: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    @include breakpoint(tablet) {
      width: 100%;
      flex: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    @include breakpoint(mobile) {
      width: 100%;
      flex: auto;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    &__logo {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
    }

    &__menu {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
    }

    &__icons {
      @include breakpoint(desktop) {
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 1;
      }
    }

    &__qr {
      @include breakpoint(desktop) {
        position: absolute;
        bottom: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 8px;
        right: 8px;
        z-index: 1;
      }
    }

    &__bg {
      @include breakpoint(desktop) {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .55;
      }
      @include breakpoint(tablet) {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .55;
      }
      @include breakpoint(mobile) {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .7;
      }
    }
  }

  &_info {
    @include breakpoint(desktop) {
      background: #e9e9e9;
      width: 100%;
      height: 300px;
      box-sizing: border-box;
      padding: 8px;
      position: relative;
    }
    @include breakpoint(tablet) {
      background: #e9e9e9;
      width: 100%;
      height: 300px;
      box-sizing: border-box;
      padding: 8px;
      position: relative;
    }
    @include breakpoint(mobile) {
      background: #e9e9e9;
      width: 100%;
      height: 208px;
      box-sizing: border-box;
      padding: 8px;
      position: relative;
    }

    &__qr {
      @include breakpoint(desktop) {
        position: absolute;
        bottom: 35px;
        left: 8px;
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 35px;
        left: 8px;
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 35px;
        left: 8px;
      }
    }

    &__tlc {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        left: 8px;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        left: 8px;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        left: 8px;
      }
    }

    &__trc {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        right: 8px;
        transform: rotate(90deg);
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        right: 8px;
        transform: rotate(90deg);
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        right: 8px;
        transform: rotate(90deg);
      }
    }

    &__brc {
      @include breakpoint(desktop) {
        position: absolute;
        bottom: 35px;
        right: 8px;
        transform: rotate(180deg);
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 35px;
        right: 8px;
        transform: rotate(180deg);
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 35px;
        right: 8px;
        transform: rotate(180deg);
      }
    }

    &__title {
      @include breakpoint(desktop) {
        position: absolute;
        top: 20px;
        left: 24px;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 20px;
        left: 24px;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 20px;
        left: 24px;
      }

      & > p {
        font-family: 'Russo One', sans-serif;

        @include breakpoint(desktop) {
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          width: fit-content;
          color: #1E1E1E;
        }
        @include breakpoint(tablet) {
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          width: fit-content;
          color: #1E1E1E;
        }
        @include breakpoint(mobile) {
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px;
          width: fit-content;
          color: #1E1E1E;
        }
      }
      & > span {
        font-family: 'Jura', sans-serif;

        @include breakpoint(desktop) {
          display: block;
          font-size: 24px;
          line-height: 24px;
          font-style: normal;
          font-weight: 700;
          width: fit-content;
          color: #1E1E1E;
          margin: 0 0 8px 0;
        }
        @include breakpoint(tablet) {
          display: block;
          font-size: 24px;
          line-height: 24px;
          font-style: normal;
          font-weight: 700;
          width: fit-content;
          color: #1E1E1E;
          margin: 0 0 8px 0;
        }
        @include breakpoint(mobile) {
          display: block;
          font-size: 24px;
          line-height: 24px;
          font-style: normal;
          font-weight: 700;
          width: fit-content;
          color: #1E1E1E;
          margin: 0 0 8px 0;
        }
      }
    }

    &__text {
      font-family: 'Jura', sans-serif;

      @include breakpoint(desktop) {
        width: fit-content;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #1E1E1E;
        position: absolute;
        bottom: 52px;
        right: 24px;
        text-align: right;
      }
      @include breakpoint(tablet) {
        width: fit-content;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #1E1E1E;
        position: absolute;
        bottom: 52px;
        right: 24px;
        text-align: right;
      }
      @include breakpoint(mobile) {
        width: fit-content;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #1E1E1E;
        position: absolute;
        bottom: 52px;
        right: 24px;
        text-align: right;
      }

      & > a {
        color: #1e1e1e;
      }
    }

    &__footerText {
      display: flex;

      @include breakpoint(desktop) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }

      &Item {
        display: flex;
        justify-content: flex-end;

        & > p {
          font-family: 'Jura', sans-serif;
          color: #1E1E1E;

          @include breakpoint(desktop) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
          @include breakpoint(tablet) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
          @include breakpoint(mobile) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
        }

        & > a {
          font-family: 'Jura', sans-serif;
          color: #1E1E1E;

          @include breakpoint(desktop) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
          @include breakpoint(tablet) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
          @include breakpoint(mobile) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
        }
      }
    }
  }
}