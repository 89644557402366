@import "src/styles/variables";
@import "src/styles/breakpoints";

.PostersPage {
  &_root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    @include breakpoint(desktop) {
      padding: 16px;
      width: 99vw;
      background: #040404;
      display: flex;
      height: fit-content;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 16px;
      overflow-y: visible;
    }
    @include breakpoint(tablet) {
      padding: 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 8px;
    }
    @include breakpoint(mobile) {
      padding: 16px;
      min-height: 100dvh;
      height: auto;
      background: #040404;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 8px;
    }
  }

  &_visual {
    @include breakpoint(desktop) {
      width: 100%;
      height: 48px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    @include breakpoint(tablet) {
      width: 100%;
      height: 48px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    @include breakpoint(mobile) {
      width: 100%;
      height: 48px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    &__logo {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 1;
      }
    }

    &__menu {
      @include breakpoint(desktop) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(tablet) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
      @include breakpoint(mobile) {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1;
      }
    }

    &__bg {
      @include breakpoint(desktop) {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .7;
      }
      @include breakpoint(tablet) {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .7;
      }
      @include breakpoint(mobile) {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: .7;
      }
    }
  }

  &_info {
    min-height: 100dvh;
    @include breakpoint(desktop) {
      //background: #e9e9e9;
      width: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
    }
    @include breakpoint(tablet) {
      //background: #e9e9e9;
      width: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
    }
    @include breakpoint(mobile) {
      //background: #e9e9e9;
      width: 100%;
      flex: auto;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &__content {
      width: 100%;
      background: #040404;
      flex: auto;
      box-sizing: border-box;

      @include breakpoint(desktop) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        padding: 0 0 32px 0;
      }
      @include breakpoint(tablet) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        padding: 0 0 32px 0;
      }
      @include breakpoint(mobile) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
      }

      &Item {
        @include breakpoint(desktop) {
          width: 100%;
          height: 400px;
          opacity: .8;
        }
        @include breakpoint(tablet) {
          width: 100%;
          height: 320px;
          opacity: .8;
        }
        @include breakpoint(mobile) {
          width: 100%;
          height: 220px;
          opacity: .8;
        }

        & > img {
          @include breakpoint(desktop) {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          @include breakpoint(tablet) {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
          @include breakpoint(mobile) {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__footerText {
      display: flex;
      background: #e9e9e9;

      @include breakpoint(desktop) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }
      @include breakpoint(tablet) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }
      @include breakpoint(mobile) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 8px;
        justify-content: space-between;
        border-top: 2px solid #1e1e1e;
      }

      &Item {
        display: flex;
        justify-content: flex-end;

        & > p {
          font-family: 'Jura', sans-serif;
          color: #1E1E1E;

          @include breakpoint(desktop) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
          @include breakpoint(tablet) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
          @include breakpoint(mobile) {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0 8px 0 0;
          }
        }

        & > a {
          font-family: 'Jura', sans-serif;
          color: #1E1E1E;

          @include breakpoint(desktop) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
          @include breakpoint(tablet) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
          @include breakpoint(mobile) {
            font-weight: normal;
            font-size: 14px;
            font-style: normal;
            line-height: normal;
          }
        }
      }
    }
  }
}